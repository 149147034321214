<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách tài khoản'">
          <template v-slot:toolbar>
            <div class="row">
              <router-link
                to="/users/add-user"
                v-show="checkPermission('USER_INSERT')"
              >
                <button
                  v-bind:style="btnCreate"
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo tài
                  khoản
                </button>
              </router-link>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <div class="col-md-3" style="padding-left: 0px">
                    <b-input
                      placeholder="Tìm kiếm theo tên đăng nhập"
                      v-model="search"
                      append-icon="search"
                      single-line
                      hide-details
                      class="form-control form-control-sm"
                      @keyup="fetchData"
                      v-on:keyup.enter="fetchData"
                    ></b-input>
                  </div>
                  <div class="col-md-3">
                    <b-input
                      placeholder="Tìm kiếm theo tên nhân viên"
                      v-model="searchEmployee"
                      append-icon="search"
                      single-line
                      hide-details
                      class="form-control form-control-sm"
                      @keyup="fetchData"
                      v-on:keyup.enter="fetchData"
                    ></b-input>
                  </div>
                </div>
              </b-form>
            </div>
            <b-table
              :items="items"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              small="true"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
              </template>
              <template v-slot:cell(stt)="row">
                <div>
                  <span
                    v-text="row.item.stt"
                    class="d-flex justify-content-center"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(username)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.username"></span>
                </div>
              </template>
              <template v-slot:cell(role)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.role"></span>
                </div>
              </template>
              <template v-slot:cell(status)="row">
                <span
                  v-if="checkStatus(row.item)"
                  v-text="row.item.status"
                  class="label font-weight-bold label-lg label-light-success label-inline"
                ></span>
                <span
                  v-else
                  v-text="row.item.status"
                  class="label font-weight-bold label-lg label-light-danger label-inline"
                ></span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="showRestorePasswordAlert(row.item)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="fas fa-redo-alt"></i>
                        &nbsp; Đổi mật khẩu
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-box-1"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('USER_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số tài khoản:
                  {{ totalRow }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  :link-gen="linkGen"
                  v-show="numberOfPage >= 2"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';

export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important'
      },
      search: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          tdClass: 'sttClass',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          thClass: 'sttClass'
        },
        {
          key: 'username',
          label: 'Tên tài khoản',
          sortable: false,
          tdClass: 'usernameClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'usernameClass'
        },
        {
          key: 'employee',
          label: 'Nhân viên',
          sortable: false,
          tdClass: 'employeeClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'employeeClass'
        },
        {
          key: 'role',
          label: 'Quyền',
          sortable: false,
          tdClass: 'roleClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'roleClass'
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          tdClass: 'statusClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'statusClass'
        },
        { key: 'actions', label: '' }
      ],
      items: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      isActive: 1,
      numberOfPage: null,
      totalRow: '',
      onLoading: false,
      count: 1,
      searchEmployee: ''
    };
  },
  components: {
    KTCodePreview,
    VclTable
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tài khoản', route: 'users' },
      { title: 'Danh sách tài khoản' }
    ]);
  },
  methods: {
    fetchData: async function() {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let param = {
        page: this.page,
        limit: 10,
        name: this.search,
        employeeName: this.searchEmployee
      };
      let paramQuery = {
        params: param
      };
      ApiService.setHeader();
      ApiService.query('user', paramQuery).then(({ data }) => {
        if (this.page === 1) {
          this.count = 1;
        } else {
          this.count = 1 + (this.page - 1) * 10;
        }
        this.items = [];
        this.numberOfPage = data.data.total_page;
        this.totalRow = data.data.total_row;
        data.data.list_user.forEach(element => {
          let status = 'Hoạt động';
          if (element.isActive === 0) {
            status = 'Đang khóa';
            this.isActive = 0;
          }
          let item = {
            id: element.id,
            stt: this.count,
            username: element.username,
            employee: element.employee,
            role: element.role,
            status: status
          };
          this.items.push(item);
          this.count++;
        });

        this.onLoading = false;
      });
      // this.onLoading = false;
    },
    editItem: function(item) {
      this.$router.push({
        path: 'users/update-user',
        query: { id: item.id }
      });
    },
    deleteItem: async function(item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.post(`user/delete/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa tài khoản!',
        text: 'Bạn có chắc muốn xóa tài khoản này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    restorePassword: async function(item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.post(`user/password/restore/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showRestorePasswordAlert: function(item) {
      Swal.fire({
        title: 'Khôi phục mật khẩu!',
        text: 'Bạn có chắc muốn khôi phục mật khẩu cho tài khoản này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.restorePassword(item);
        }
      });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    checkStatus: function(item) {
      if (item.status === 'Hoạt động') {
        return true;
      } else {
        return false;
      }
    },
    checkViewOnly: function() {
      let count = 0;
      if (localData.checkPermission('USER_VIEW')) {
        count++;
      }
      if (localData.checkPermission('USER_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 6%;
}
.usernameClass {
  width: 25%;
}
.employeeClass {
  width: 25%;
}
.roleClass {
  width: 20%;
}
.satusClass {
  width: 14%;
}
</style>
<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
